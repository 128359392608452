import {
  Breadcrumb,
  Button,
  Card,
  Form,
  Icon,
  Input,
  InputNumber,
  message,
  Select,
  Spin,
} from "antd"
import { gql } from "apollo-boost"
import { Link } from "gatsby"
import qs from "query-string"
import React from "react"
import { Mutation, Query } from "react-apollo"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const { Option } = Select

const formItemLayoutWithLabel = {
  labelCol: { xs: { span: 24 }, sm: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
}

const formItemLayoutWithoutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
}

const UPDATE_ITEM = gql`
  mutation updateItem($id: Int, $changes: mbt_items_set_input) {
    update_mbt_items(where: { id: { _eq: $id } }, _set: $changes) {
      returning {
        category {
          name
        }
        code
        name
        unit
        initial_stock
        current_stock
      }
    }
  }
`

const LIST_ITEM_CATEGORIES = gql`
  query listItemCategories {
    mbt_item_categories(order_by: { name: asc }) {
      id
      name
    }
  }
`

const ItemForm = Form.create({
  mapPropsToFields: ({ item = {} }) => ({
    category_id: Form.createFormField({ value: item.category_id }),
    code: Form.createFormField({ value: item.code }),
    name: Form.createFormField({ value: item.name }),
    unit: Form.createFormField({ value: item.unit }),
    current_stock: Form.createFormField({ value: item.current_stock }),
  }),
})(({ item, form, loading: queryLoading }) => (
  <Mutation mutation={UPDATE_ITEM}>
    {(updateItems, { loading }) => {
      const disabled = queryLoading || loading
      return (
        <Form
          {...formItemLayoutWithLabel}
          onSubmit={e => {
            e.preventDefault()
            form.validateFieldsAndScroll(async (errors, changes) => {
              if (!errors) {
                try {
                  await updateItems({
                    variables: { id: item.id, changes },
                  })
                  form.setFieldsInitialValue(changes)
                  message.success("Successfully edited item")
                } catch (err) {
                  message.error("Failed to edit item")
                }
              }
            })
          }}
        >
          <Query query={LIST_ITEM_CATEGORIES}>
            {({ data, loading }) => (
              <Form.Item label="Category">
                {form.getFieldDecorator("category_id", {
                  rules: [
                    {
                      required: true,
                      message: "Category is required",
                    },
                  ],
                })(
                  <Select
                    disabled={disabled}
                    notFoundContent={loading ? <Spin size="small" /> : null}
                    optionFilterProp="children"
                    showSearch
                  >
                    {(data.mbt_item_categories || []).map(({ id, name }) => (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            )}
          </Query>
          <Form.Item label="Code">
            {form.getFieldDecorator("code", {
              rules: [
                {
                  required: true,
                  message: "Code is required",
                  whitespace: true,
                },
              ],
            })(<Input disabled={disabled} />)}
          </Form.Item>
          <Form.Item label="Name">
            {form.getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Name is required",
                  whitespace: true,
                },
              ],
            })(<Input disabled={disabled} />)}
          </Form.Item>
          <Form.Item label="Unit">
            {form.getFieldDecorator("unit", {
              rules: [
                {
                  required: true,
                  message: "Unit is required",
                },
              ],
            })(
              <Select disabled={disabled}>
                <Option value="klg">klg</Option>
                <Option value="pck">pck</Option>
                <Option value="pcs">pcs</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Initial stock">
            {form.getFieldDecorator("initial_stock", {
              initialValue: 0,
              rules: [{ type: "integer" }],
            })(
              <InputNumber
                disabled={disabled}
                min={0}
                style={{ width: "100%" }}
              />
            )}
          </Form.Item>
          <Form.Item label="Current stock">
            {form.getFieldDecorator("current_stock", {
              initialValue: 0,
              rules: [{ type: "integer" }],
            })(
              <InputNumber
                disabled={disabled}
                min={0}
                style={{ width: "100%" }}
              />
            )}
          </Form.Item>
          <Form.Item {...formItemLayoutWithoutLabel}>
            <Button
              type="primary"
              disabled={!form.isFieldsTouched()}
              htmlType="submit"
              loading={loading}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      )
    }}
  </Mutation>
))

const GET_ITEM = gql`
  query getItem($id: Int!) {
    mbt_items_by_pk(id: $id) {
      id
      category_id
      code
      name
      unit
      initial_stock
      current_stock
    }
  }
`

export default ({ location }) => {
  const { id } = qs.parse(location.search)
  return (
    <Layout>
      <SEO title="Item" />
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to="/">
            <Icon type="home" />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/item/">Item</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Query
          fetchPolicy="cache-and-network"
          query={GET_ITEM}
          variables={{ id }}
        >
          {({ data, loading }) => (
            <ItemForm item={data.mbt_items_by_pk} loading={loading} />
          )}
        </Query>
      </Card>
    </Layout>
  )
}
